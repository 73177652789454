
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { loginRules, mobileLoginRules } from './rules/login-rules'
import { IResponse } from '../common/http'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

interface ILoginForms {
  username: string,
  password: string
}

interface IMobileLoginForms {
  mobile: string,
  captcha: string
}

declare let globalConfig:any

export default defineComponent({
  name: 'Login',
  setup () {
    const { proxy } : any = getCurrentInstance()
    const config = globalConfig
    const loginType = ref(true)
    const sendSms = ref(false)
    const router = useRouter()
    const store = useStore()
    // 用户名登录表单字段
    const loginForms = ref<ILoginForms>({
      username: '',
      password: ''
    })

    // 手机号登录表单字段
    const mobileLoginForms = ref<IMobileLoginForms>({
      mobile: '',
      captcha: ''
    })

    const toForget = (): void => {
      router.push({ name: 'Forget' })
    }

    const toRegister = (): void => {
      router.push({ name: 'Register' })
    }

    // 登录操作
    const handleLogin = (formName: string): void => {
      proxy.$refs[formName].validate((valid: boolean) => {
        if (valid) {
          switch (formName) {
            case 'loginForm':
              proxy.$axios.post('/user/login', loginForms.value)
                .then((res: IResponse) => {
                  loginAfter(res)
                })
              break
            case 'mobileLoginForm':
              proxy.$axios.post('/user/mobilelogin', mobileLoginForms.value)
                .then((res: IResponse) => {
                  loginAfter(res)
                })
              break
          }
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.error('字段有误')
          }
          return false
        }
      })
    }

    // 登录完成
    const loginAfter = (res: IResponse): void => {
      if (res.code === 1) {
        // ElMessage.success({
        //   message: res.msg,
        //   type: 'success'
        // })
        store.commit('Login', res.data)
        setTimeout(() => {
          router.push({ name: 'Shorten' })
        }, 200)
      } else {
        ElMessage.warning({
          message: res.msg,
          type: 'warning'
        })
      }
    }

    // 获取验证码
    const handleSms = (): void => {
      const mobile: string = mobileLoginForms.value.mobile
      if (mobile !== '') {
        sendSms.value = true
        proxy.$axios.post('/sms/send', {
          mobile: parseInt(mobile),
          event: 'login'
        }).then((res: IResponse) => {
          sendSms.value = false
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else {
        ElMessage.warning({
          message: '请先输入手机号再获取验证码',
          type: 'warning'
        })
      }
    }

    // 切换登录方式
    const cutover = (): void => {
      loginType.value = !loginType.value
    }

    return {
      loginForms,
      loginRules,
      mobileLoginForms,
      handleLogin,
      loginType,
      mobileLoginRules,
      cutover,
      handleSms,
      sendSms,
      toForget,
      toRegister,
      config
    }
  }
})
