<template>
  <div class="Login">
    <el-row>
      <el-col :xs="{span: 22, offset: 1}" :sm="{span: 12, offset: 6}" :md="{span: 8, offset: 8}" :lg="{span: 6, offset: 9}" :xl="{span: 4, offset: 10}">
        <h3 class="title">
          <router-link :to="{ name: 'Index' }">
            {{ config.name }}
          </router-link>
        </h3>
        <h4 class="desc">登录您的账号</h4>
        <transition name="el-fade-in" mode="out-in">
          <el-form v-if="loginType" ref="loginForm" status-icon :model="loginForms" :rules="loginRules">
            <el-form-item label="用户名" prop="username">
              <el-input placeholder="请输入用户名" clearable v-model="loginForms.username" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input placeholder="请输入密码" show-password v-model="loginForms.password" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="submit" type="primary" @click="handleLogin('loginForm')">登录</el-button>
              <el-button type="text" @click="toRegister">
                还没账号？立即注册
              </el-button>
            </el-form-item>
            <el-divider content-position="center">其它选择</el-divider>
            <el-form-item class="other">
              <!-- <el-button @click="cutover" type="primary" plain icon="el-icon-mobile-phone">手机登录</el-button> -->
              <el-button icon="el-icon-unlock" @click="toForget">
                忘记密码
              </el-button>
            </el-form-item>
          </el-form>
          <!-- <el-form v-else ref="mobileLoginForm" status-icon :model="mobileLoginForms" :rules="mobileLoginRules">
            <el-form-item label="手机号" prop="mobile">
              <el-input placeholder="请输入手机号" clearable v-model="mobileLoginForms.mobile" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="captcha">
              <el-input placeholder="请输入验证码" v-model="mobileLoginForms.captcha" auto-complete="off">
                <template #append>
                  <el-button @click="handleSms" :loading="sendSms">获取验证码</el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="submit" type="primary" @click="handleLogin('mobileLoginForm')">登录</el-button>
              <el-button type="text" @click="toRegister">
                还没账号？立即注册
              </el-button>
            </el-form-item>
            <el-divider content-position="center">其它选择</el-divider>
            <el-form-item class="other">
              <el-button @click="cutover" type="primary" plain icon="el-icon-mobile-phone">账号登录</el-button>
              <el-button icon="el-icon-unlock" @click="toForget">
                忘记密码
              </el-button>
            </el-form-item>
          </el-form> -->
        </transition>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { loginRules, mobileLoginRules } from './rules/login-rules'
import { IResponse } from '../common/http'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

interface ILoginForms {
  username: string,
  password: string
}

interface IMobileLoginForms {
  mobile: string,
  captcha: string
}

declare let globalConfig:any

export default defineComponent({
  name: 'Login',
  setup () {
    const { proxy } : any = getCurrentInstance()
    const config = globalConfig
    const loginType = ref(true)
    const sendSms = ref(false)
    const router = useRouter()
    const store = useStore()
    // 用户名登录表单字段
    const loginForms = ref<ILoginForms>({
      username: '',
      password: ''
    })

    // 手机号登录表单字段
    const mobileLoginForms = ref<IMobileLoginForms>({
      mobile: '',
      captcha: ''
    })

    const toForget = (): void => {
      router.push({ name: 'Forget' })
    }

    const toRegister = (): void => {
      router.push({ name: 'Register' })
    }

    // 登录操作
    const handleLogin = (formName: string): void => {
      proxy.$refs[formName].validate((valid: boolean) => {
        if (valid) {
          switch (formName) {
            case 'loginForm':
              proxy.$axios.post('/user/login', loginForms.value)
                .then((res: IResponse) => {
                  loginAfter(res)
                })
              break
            case 'mobileLoginForm':
              proxy.$axios.post('/user/mobilelogin', mobileLoginForms.value)
                .then((res: IResponse) => {
                  loginAfter(res)
                })
              break
          }
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.error('字段有误')
          }
          return false
        }
      })
    }

    // 登录完成
    const loginAfter = (res: IResponse): void => {
      if (res.code === 1) {
        // ElMessage.success({
        //   message: res.msg,
        //   type: 'success'
        // })
        store.commit('Login', res.data)
        setTimeout(() => {
          router.push({ name: 'Shorten' })
        }, 200)
      } else {
        ElMessage.warning({
          message: res.msg,
          type: 'warning'
        })
      }
    }

    // 获取验证码
    const handleSms = (): void => {
      const mobile: string = mobileLoginForms.value.mobile
      if (mobile !== '') {
        sendSms.value = true
        proxy.$axios.post('/sms/send', {
          mobile: parseInt(mobile),
          event: 'login'
        }).then((res: IResponse) => {
          sendSms.value = false
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else {
        ElMessage.warning({
          message: '请先输入手机号再获取验证码',
          type: 'warning'
        })
      }
    }

    // 切换登录方式
    const cutover = (): void => {
      loginType.value = !loginType.value
    }

    return {
      loginForms,
      loginRules,
      mobileLoginForms,
      handleLogin,
      loginType,
      mobileLoginRules,
      cutover,
      handleSms,
      sendSms,
      toForget,
      toRegister,
      config
    }
  }
})
</script>

<style lang="scss" scoped>
  .Login {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #409EFF;
    .el-row {
      width: 100%;
    }
    .el-col {
      max-width: 360px;
      min-width: 240px;
      margin: 0 auto;
      padding: 30px 24px;
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(16,16,17,.02), 0 3.4px 8px rgba(16,16,17,.007), 0 12px 30px rgba(16,16,17,.003);
      animation: fade-in 0.5s;
      background: rgba(255, 255, 255);
      .title {
        color: #303133;
        line-height: 1.8;
      }
      .desc {
        color: #303133;
        font-weight: 400;
        line-height: 2.4;
      }
      .other {
        display: flex;
        justify-content: center;
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 320px) {
    .Login {
      .el-button {
        display: block;
        width: 100%;
      }
      .other {
        flex-direction: column;
        .el-button {
          margin-top: 10px;
        }
      }
      .el-button+.el-button {
        margin-left: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-divider--horizontal {
        margin: 12px 0;
      }
      .submit.el-button {
        margin-top: 12px;
      }
    }
  }
</style>
