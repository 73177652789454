import { ref } from 'vue'
import { validateUsername, validatePassword, validateMobile, validateCaptcha } from './rules'

// 拦截账号登录规则接口
interface ILoginRules {
  username: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
  password: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
}

// 拦截账号登录规则
export const loginRules = ref<ILoginRules>({
  username: [
    {
      min: 2,
      max: 30,
      message: '用户名长度在2到30个字符',
      trigger: 'blur'
    },
    {
      validator: validateUsername,
      trigger: 'blur'
    }
  ],
  password: [
    {
      min: 6,
      max: 30,
      message: '密码长度必须在6到30个字符',
      trigger: 'blur'
    },
    {
      validator: validatePassword,
      trigger: 'blur'
    }
  ]
})

interface IMobileLoginRules {
  mobile: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
  captcha: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
}

// 拦截手机号登录规则
export const mobileLoginRules = ref<IMobileLoginRules>({
  mobile: [
    {
      min: 11,
      max: 11,
      message: '只支持11位手机号',
      trigger: 'blur'
    },
    {
      validator: validateMobile,
      trigger: 'blur'
    }
  ],
  captcha: [
    {
      min: 4,
      max: 8,
      message: '验证码长度必须在4到8个字符',
      trigger: 'blur'
    },
    {
      validator: validateCaptcha,
      trigger: 'blur'
    }
  ]
})
